.text-small {
  @include font-size-variant("small");
}

.text-medium {
  @include font-size-variant("medium");
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}


.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 5px;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.uppercase {
  text-transform: uppercase;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}



.primary-text-color {
  color: $primary-color;
}

.white-color-forced {
  color: $white !important;
}

.subtle-text {
  color: $subtle-text-color !important;
}

.forgot-passowrd-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0px 10px
}

.forget-passowrd-button {
  color: $subtle-text-color !important;
}

.color-checker {
  width: 20px;
  height: 20px;
  background-color: $primary-color;
}

.w-100 {
  width: 100%;
}

.mt-0 {
  margin-top: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.font-monstserrat {
  font-family: $font-family-montserrat !important;
}

.font-semibold {
  font-weight: 500;
}

.font-bold {
  font-weight: $font-weight-bold !important;
}

.font-boldest {
  font-weight: $font-weight-boldest !important;
}

.label-full-width {
  label {
    width: 100%;
  }
}

.touch-action-none {
  touch-action: none;
}


.position-relative {
  position: relative;
}

.absolute-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mt-auto {
  margin-top: auto;
}

.m-auto {
  margin: auto;
}

.flex-column {
  flex-direction: column;
}

.btn-small-text {
  span {
    font-size: 0.75rem;

    &:last-child {
      margin-inline-start: 4px;
    }
  }
}

.inner-span-small {
  span {
    font-size: 0.64rem;
  }
}

.inner-span-medium-text {
  span {
    font-size: 0.8rem;
  }
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.min-width-0 {
  min-width: 0;
}

.min-width-100 {
  min-width: 100%;
}

.black-icon {
  &:not(:hover) {
    color: black;
  }
}

.justify-content-center {
  justify-content: center !important;
}


.rotate-270 {
  transform: rotate(270deg);
}

.w-fit-content {
  width: fit-content;
}

.position-fixed {
  position: fixed;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.secondary-text-color {
  color: $seconday-text-color;
}