@use 'styles/scss/variables.scss' as vars;

.card-layout {
  width: 100%;
  background-color: vars.$white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .card-layout-header {
    padding: 10px 12px;
    border-bottom: 1px solid vars.$header-border-color;
  }

  .card-layout-body {
    padding: 10px 12px;
    overflow-y: auto;
    flex: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.success-btn {
  background-color: $success-color;

  &:hover {
    &:not(:disabled) {
      background-color: lighten($color: $success-color, $amount: 5) !important;
    }
  }
}


.count-card {
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  height: 100%;

  .count-left-side {
    margin-inline-start: 10px;
    display: flex;
    flex-direction: column;

    .count-actions {
      display: flex;
      align-items: center;
    }
  }

  &-desc {
    font-size: 0.7rem;
  }

  &-title {
    font-size: 1rem;
    font-weight: 600;
  }

  &-number {
    font-size: 1.6rem;
    font-weight: 800;
  }
}