html {
  font-size: 14px; // base size

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    font-size: 14px;
  }

  // Medium devices (tablets, 768px and up)
  @media (max-width: 768px) and (min-width: 578px) {
    font-size: 14px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 769px) and (max-width: 1399px) {
    font-size: 16px;
  }

  // Extra-large devices (large desktops, 1200px and up)
  @media (min-width: 1400px) {
    font-size: 18px;
  }
}

h1 {
  font-size: $font-size-xlarge;
}

h2 {
  font-size: $font-size-large;
}

h3 {
  font-size: $font-size-medium;
}

h4 {
  font-size: $font-size-base;
}

p {
  font-size: $font-size-base;
}