.logo {
  background-image: url("../../assets/directory/gas-icon.svg");
  height: 40px;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
}




.auth-layout-wrapper {
  height: 100vh;
  background-color: $content-background-color;
  display: flex;
  gap: 20px;
  padding: 20px 5%;

  .auth-left {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;

    .app-logo {
      background-image: url("../../assets/directory/gas-icon-stacked.svg");
      max-width: 300px;
      max-height: 300px;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
  }

  .auth-right {
    display: flex;
    justify-content: center;
    flex: 1;
    overflow-y: auto;
    align-items: center;
    flex: 0.5;


    .auth-form-wrapper {
      padding: 10px 50px 30px 50px;
      width: 100%;
      min-height: 450px;
      max-width: 420px;
      background-color: $white;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 7px;
    }

    .helper-text {
      margin-bottom: 30px;
    }

    .login-btn {
      height: 40px;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  @media (max-width : 700px) {
    .auth-left {
      display: none;
    }

    .auth-right {
      flex: 1;
    }
  }
}

.default-layout-wrapper {
  height: 100vh;
  height: 100svh;


  .default-layout-sider {
    background-color: $sider-bg-color;



    .logo-divider {
      border-color: #ffffff20;
    }

    .sider-menu {
      background-color: $sider-bg-color;
    }

    .ant-layout-sider-trigger {
      background-color: $sider-bg-color;
    }
  }



  .page-header {
    display: flex;
    padding-inline: 20px;
    background-color: $content-background-color;
    gap: 10px;
    line-height: normal;
    align-items: center;
    position: relative;

    &-left {
      display: flex;
      gap: 8px;
      align-items: center;


      .page-title {
        text-transform: capitalize;
        font-size: 1.37rem;
      }
    }

    &-center {

      .date-navigator {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        flex-shrink: 0;

        .schedular-icon {
          font-size: 1.9rem;
          flex-shrink: 0;
        }

        .picker {
          position: absolute;
          z-index: 1;
          inset: 0;
          opacity: 0;
          cursor: pointer;

          &::after {
            content: " ";
            inset: 0;
            position: absolute;
          }
        }

        .date-text {
          text-align: center;
        }

      }
    }

    &-right {
      display: inline-flex;
      gap: 14px;
      align-items: center;
      justify-content: flex-end;

      .dashboard-buttons-wrapper {
        display: inline-flex;
        gap: 10px;
        margin-right: 20px;
      }

      .user-avatar {
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
      }

      .user-info {
        .role {
          background-color: $primary-color;
          color: $white;
          padding: 2px 8px;
          font-size: $font-size-xsmall;
          line-height: normal;
          border-radius: 7px;
          display: inline-block;
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .logo {
    margin-top: 8px;
    color: $white;
  }

  .default-layout-inner {
    background-color: $content-background-color;

    .default-layout-content {
      margin: 8px 16px;
      min-height: 280px;
      height: 100%;
      overflow-y: auto;
      background-color: $content-background-color;
    }

    &.board {
      .default-layout-content {
        border-radius: 8px;
      }

    }
  }

  .action-buttons-wrapper,
  .dashboard-tags-wrapper,
  .dashboard-selects-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
  }

}