@use 'styles/scss/variables.scss' as vars;

.notifications-drawer {

    .ant-drawer-body {
        background-color: vars.$content-background-color;
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .notification-card {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        background-color: white;
        border-radius: 4px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-shrink: 0;


        &-desc {
            font-size: 14px;
        }
    }

}