// colors
$primary-color: #80adb4;
$accent-color : #1677FF;
$default-color: #939393;
$grey: #727272;
$black: #323232;
$white: #ffffff;
$light-grey: #ededed;
$red: #ff0000;
$subtle-text-color: #626262;
$seconday-text-color : #666666;

//#626262

// status colors
$success-color: #00b300;
$warning-color: #ffb300;
$error-color: #ff0000;

// background Colors
$content-background-color: #f7f8fc;
$sider-bg-color: #363740;
$auth-bg: #363740;

// font sizes
$font-size-xsmall: 0.625rem; // 10px / html base fontsize
$font-size-small: 0.75rem; // 12px / html base fontsize
$font-size-base: 0.875rem; // 14px / html base fontsize
$font-size-medium: 1.125rem; // 18px / html base fontsize
$font-size-large: 1.25rem; // 20px / html base fontsize
$font-size-xlarge: 1.5rem; // 24px / html base fontsize
$font-size-huge: 2.313rem; // 37px / html base fontsize

// font weights for normal , semibold , and bold
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$font-weight-boldest : 800;

// header height
$page-navbar-height: 80px;

// fonts

$font-family-montserrat: "Montserrat";

// schedular props

$schedular-border-color: #e6e6e6;
$time-gutter-bg: $white;
$resource-header-bg: $white;
$schedular-content-height: 100%;
$header-bg: #fafafa;
$container-border-color: #e6e6e6;
$header-height: 53px;
$header-padding: 0 10px;
$content-padding: 8px 10px;
$header-border-color: #00000010;
$header-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);


// text color dark

$text-color-normal: #fffff5db;
$text-color-dim : #ebebf599;
$text-color-dimmer : #ffffff99;

// border color
$border-color-dim : #525259ad;
$border-color-dimmer : #52525954;


$surface-dark: #161618;