@use 'styles/scss/variables.scss' as vars;

.subloader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000095;
  z-index: 99999;
  width: 100%;
  height: 100%;

  &.overlay {
    position: fixed;
    inset: 0;
  }

  .lds-roller div:after {
    background: vars.$primary-color !important;
  }
}

.app-loader {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: white;



}

.loader-wrapper {

  .app-entry-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;



    .app-entry-logo {
      width: 36vw;
      height: 36vh;
      background-image: url('../../assets/directory/gas-icon-stacked.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }


  .antd-spin {
    font-size: 3.4rem;
    color: vars.$primary-color !important;


    &.entry {
      margin-right: 8px;
      font-size: 2.5rem;
      opacity: 0;
      animation-name: show;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }


  &.lazy {
    height: 100%;
    background-color: transparent;


    .antd-spin {
      color: vars.$primary-color !important;
    }
  }

}



@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }

}