* {
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

body {
  margin: 0;
  font-family: Nunito Sans, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-form-item-explain-error {
  font-size: $font-size-small;
}

.ant-form-item-label {
  font-weight: $font-weight-bold;
}

mark {
  background-color: transparent;
}


.ant-menu-item-selected {
  a {
    font-weight: $font-weight-bold;
  }
}

.ant-descriptions-item-label {
  font-weight: $font-weight-bold !important;
}


.ant-table-thead {
  .ant-table-cell {

    font-weight: 700 !important;

  }
}

.ant-tabs-tab {
  &-active {
    background: $primary-color !important;
    color: white !important;

    .ant-tabs-tab-btn {
      font-weight: bold;
      color: white !important;
    }
  }
}



.ant-btn {
  .ri-centered {
    margin-top: 3px;
  }
}

.ant-tour {
  &-title {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
  }
&-description {
  font-size: 0.8rem !important;
}
}