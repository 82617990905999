.illustration-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  flex-direction: column;
  text-align: center;

  img {
    max-width: 300px;
    object-fit: contain;
    max-height: 200px;
  }

  h3 {
    margin-top: 20px;
  }
}
