.provider-on-board {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 15px;

    &>img {
        max-width: 300px;
        width: 100%;
        height: auto;
    }

    &-texts {
        display: flex;
        flex-direction: column;
        gap: 7px;
        text-align: center;

    }

    &>button {
        height: 50px;
        font-size: 1rem;
        font-weight: 500;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .anticon-arrow-right {
            transition: 0.3s transform;
        }

        &:hover {
            .anticon-arrow-right {
                transform: translateX(10px);
            }
        }
    }
}