@use "styles/scss/variables.scss" as vars;

.custom-modal {
  .ant-modal-header {
    margin-bottom: 0;
  }

  .ant-modal-content {
    padding: 0;
  }

  .custom-modal-header {
    padding: 12px 20px;
    border-bottom: 1px solid #e5e5e570;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .custom-modal-body {
    padding: 15px 20px;
    background-color: vars.$content-background-color;


  }

  &:not(:has(.custom-modal-footer)) {
    .custom-modal-body {
      border-radius: 0 0 8px 8px;
    }
  }

  .custom-modal-footer {
    border-top: 1px solid #e5e5e570;
    padding: 12px 20px;
    display: flex;
    flex-direction: row-reverse;
    gap: 7px;
  }
}