* {
  box-sizing: border-box;
}

:root {
  --border-color: #00000010;
  --time-slot-gutter-width: 50px;
  --resource-header-height: 52px;
  --slot-wrapper-min-height: 100px;
  --column-min-width: 170px;
}

// Large devices (between 769px and 992px)
@media (max-width: 992px) {
  :root {
    --time-slot-gutter-width: 50px;
    --column-min-width: 150px;
  }
}

// Medium devices (between 577px and 768px)
@media (max-width: 768px) {
  :root {
    --resource-header-height: 40px;
    --slot-wrapper-min-height: 80px;
    --time-slot-gutter-width: 45px;
    --column-min-width: 130px;
  }
}

// Small devices (up to 576px)
@media only screen and (max-width: 576px) {
  :root {
    --resource-header-height: 40px;
    --slot-wrapper-min-height: 80px;
    --time-slot-gutter-width: 40px;
    --column-min-width: 110px;
  }
}




.schedular {
  flex: 1;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: white;
  overflow-x: scroll;

  .resource-header {
    display: flex;
    height: var(--resource-header-height);
    border-bottom: 1px solid var(--border-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    flex-shrink: 0;
    min-width: fit-content;
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 10;

    .resource-gutter {
      width: var(--time-slot-gutter-width);
      height: 100%;
      background-color: white;
      z-index: 10;
      border-right: 1px solid var(--border-color);
      position: sticky;
      left: 0;
      flex-shrink: 0;
    }

    .resource-header-inner {
      overflow-x: hidden;
      display: flex;
      scroll-behavior: auto;
      width: 100%;

      .resource {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex: 1 0;
        border-right: 1px solid var(--border-color);
        min-width: var(--column-min-width);

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .schedular-content {
    flex: 1 0;
    min-width: fit-content;


    &-inner {
      display: flex;
      min-height: 0;
      position: relative;
      z-index: 9;

      .time-slots-wrapper {
        display: flex;
        flex-direction: column;
        width: var(--time-slot-gutter-width);
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: white;

        .time-slot {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid var(--border-color);
          min-height: var(--slot-wrapper-min-height);
          flex: 1;
          position: relative;
          width: var(--time-slot-gutter-width);

          &:first-child {
            &>span {
              top: 0;
            }
          }

          &:last-child {
            border-bottom: 0;
          }

          &>span {
            position: absolute;
            top: -10px;
            z-index: 2;
            font-size: 0.8rem;
            text-align: center;
            font-weight: 300;
          }
        }
      }

      .cells-wrapper {
        position: relative;
        display: flex;
        flex: 1;
        width: calc(100% - var(--time-slot-gutter-width));

        .cell-group {
          flex: 1;
          display: flex;
          flex-direction: column;
          min-width: var(--column-min-width);
          border-right: 1px solid var(--border-color);

          &.last {
            border-right: 0;
          }

          .cell {
            flex: 1;
            border-bottom: 1px solid var(--border-color);
            min-height: var(--slot-wrapper-min-height);

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }

    .event-card {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: 0.1s transform linear;
      touch-action: none;
      user-select: none;
      overflow: hidden;
      container-type: inline-size;

      .ec-card-inner {
        height: 100%;
        position: relative;

        .basic {
          background-color: rgba($color: green, $alpha: 0.2);
          height: 100%;
          font-size: 0.8rem;
          padding: 4px 7px;
        }

        .resize-handle {
          width: 10px;
          height: 10px;
          background-color: #333;
          cursor: nwse-resize;
          /* change cursor on hover to indicate resize functionality */
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 9;
        }
      }

      &.is-dragging {
        opacity: 0.5;
      }
    }
  }

  .touch-dnd-preview {
    padding: 10px;
    border-radius: 10px;
    background-color: #80808030;
    color: white;
  }
}